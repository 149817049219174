import { useEffect, useState } from 'react';

import getT from 'next-translate/getT';

const i18nKeyData = (key) => {
    const keyData = {
        name: 'nazwa',
        value: key,
        url: 'https://cos',
    };

    const keyStringifyData = JSON.stringify(keyData)
        .split('')
        .map((ch) => ('00000000' + ch.charCodeAt(0).toString(2)).slice(-8))
        .join('');

    return keyStringifyData.replaceAll('0', '\u{FEFF}').replaceAll('1', '\u{200B}');
};

export default function withI18nData(oldT, namespaces) {
    var t = function (key, query, options) {
        return '\u{200D}\u{200C}' + i18nKeyData(key) + oldT(key, query, options) + '\u{200C}\u{200D}';
    };
    return t;
}

import Link from 'next/link';
import { useRouter } from 'next/router';

import { getNextLink } from '@helpers/getLink';

interface CustomLinkProps {
    href: any; // TODO: brak poprawnego typowania
    target?: '_self' | '_blank';
    scroll?: boolean;
    locale?: string;
    className?: string;
    prefetch?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    onMouseEnter?: (e: React.MouseEvent) => void;
    onMouseLeave?: (e: React.MouseEvent) => void;
    [x: string]: any;
}

export default function CustomLink({ href, target, locale, children, scroll = true, className, onClick, onMouseEnter, onMouseLeave, prefetch = false, ...props }: React.PropsWithChildren<CustomLinkProps>): JSX.Element {
    // TODO: brak obslugi hash
    const router = useRouter();
    const finalLocale = locale ?? router.locale;

    return (
        <Link
            href={href.pathname === '/' && finalLocale === 'en' ? href : getNextLink(href, finalLocale)}
            target={target}
            locale={false}
            prefetch={prefetch}
            scroll={scroll}
            className={className}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...props}
        >
            {children}
        </Link>
    );
}

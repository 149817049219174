import { useRouter } from 'next/router';

import CustomLink from '@components/global/CustomLink';

import useTranslation from '@hooks/useTranslation';
import {
    laravelRouteFeatures,
    laravelRouteFeaturesEmailMarketing,
    laravelRouteFeaturesEmailMarketingAutoresponder,
    laravelRouteFeaturesLandingPageCreator,
    laravelRouteFeaturesMarketingAutomation,
    laravelRouteFeaturesPopupCreator,
    laravelRouteFeaturesWebsiteBuilder,
    laravelRouteIntegrations,
} from '@static_components/laravelLinks';
import clsx from 'clsx';

import styles from './MenuProduct.module.css';

interface FooterMenuProductProps {
    darkMode?: boolean;
}

const FooterMenuProduct = ({ darkMode = false }: FooterMenuProductProps) => {
    const { t } = useTranslation('base/_blocks/footer');
    const { locale } = useRouter();

    return (
        <div className={clsx(styles.block, darkMode ? styles.darkMode : '')}>
            <h3>{t('product_header')}</h3>
            <ul className={styles['sites']}>
                <li>
                    <a
                        href={laravelRouteFeaturesEmailMarketing(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_email_marketing')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'features/email-marketing/ai-email-generator' }}
                        data-test="footerLink"
                    >
                        {t('menu_ai_email_generator')}
                    </CustomLink>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'features/ai-campaign-generator' }}
                        data-test="footerLink"
                    >
                        {t('menu_ai_campaign_generator')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesEmailMarketingAutoresponder(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_autoresponders')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesMarketingAutomation(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_automation')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesWebsiteBuilder(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_website_builder')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesLandingPageCreator(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_landing_pages')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeaturesPopupCreator(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_forms_popups')}
                    </a>
                </li>
                <li>
                    <a
                        href={laravelRouteFeatures(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_all_features')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'pricing/trial' }}
                        data-test="footerLink"
                    >
                        {t('menu_pricing_trial')}
                    </CustomLink>
                </li>
                <li>
                    <a
                        href={laravelRouteIntegrations(locale)}
                        data-test="footerLink"
                    >
                        {t('menu_integrations')}
                    </a>
                </li>
                <li>
                    <CustomLink
                        href={{ pathname: 'pricing' }}
                        data-test="footerLink"
                    >
                        {t('menu_pricing')}
                    </CustomLink>
                </li>
            </ul>
        </div>
    );
};

export default FooterMenuProduct;

import { useMemo } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { alternates } from '@helpers/alternates';

import clsx from 'clsx';

import FooterApps from './Components/FooterApps';
import FooterBottom from './Components/FooterBottom';
import FooterColumns from './Components/FooterColumns';
import FooterContainer from './Components/FooterContainer';
import FooterInfo from './Components/FooterInfo';
import FooterLang from './Components/FooterLang';
import FooterLegal from './Components/FooterLegal';
import FooterMenuCompany from './Components/FooterMenu/MenuCompany';
import FooterMenuProduct from './Components/FooterMenu/MenuProduct';
import FooterMenuResources from './Components/FooterMenu/MenuResources';
import FooterRecentPosts from './Components/FooterRecentPosts';
import FooterSocial from './Components/FooterSocial';
import FooterSupplemental from './Components/FooterSupplemental';
import styles from './Footer.module.css';

const FooterAccessibility = dynamic(() => import('./Components/FooterAccessibility'), {
    ssr: false,
});

export interface FooterProps {
    darkMode?: boolean;
    recentPostsComponent?: React.Component;
    footerLegalComponent?: React.ReactElement;
    alternateQuery?: boolean | object;
    alternateUrls?: Array<{
        hrefLang: string;
        href: string;
    }>;
    hidden?: boolean;
    footerInternalLinks?: boolean;
    langSwitcherPathname?: string;
}

const Footer = ({ darkMode = false, recentPostsComponent, footerLegalComponent, alternateQuery, alternateUrls, hidden, footerInternalLinks = true, langSwitcherPathname }: FooterProps) => {
    const router = useRouter();
    const alternateLinks = useMemo(() => alternates(langSwitcherPathname ?? router.pathname, alternateQuery), [alternateQuery, router.pathname, langSwitcherPathname]);
    const initialLocales = alternateLinks.map((hreflang) => hreflang.hrefLang).filter((lang) => lang !== 'x-default');
    let supportedLocales = initialLocales.map((locale) => (locale === 'vn' ? 'vi' : locale));
    let supportedURLs = alternateLinks.filter((item) => item.hrefLang !== 'x-default');

    if (alternateUrls) {
        supportedLocales = alternateUrls
            .map((hreflang) => hreflang.hrefLang)
            .filter((lang) => lang !== 'x-default')
            .map((locale) => (locale === 'vn' ? 'vi' : locale));
        supportedURLs = alternateUrls.filter((item) => item.hrefLang !== 'x-default');
    }

    return !hidden ? (
        <footer className={clsx(styles.mfooter, darkMode ? styles.darkMode : '')}>
            {footerInternalLinks && (
                <FooterContainer
                    darkMode={darkMode}
                    className={styles.top}
                >
                    <FooterColumns>
                        <FooterMenuProduct darkMode={darkMode} />
                        <FooterMenuResources darkMode={darkMode} />
                        <FooterMenuCompany darkMode={darkMode} />
                        {recentPostsComponent ? recentPostsComponent : <FooterRecentPosts darkMode={darkMode} />}
                    </FooterColumns>
                </FooterContainer>
            )}
            <FooterContainer
                darkMode={darkMode}
                className={styles.bottom}
            >
                <FooterSupplemental>
                    <FooterApps darkMode={darkMode} />
                    <FooterSocial darkMode={darkMode} />
                    <FooterLang
                        darkMode={darkMode}
                        locales={supportedLocales}
                        supportedURLs={supportedURLs}
                    />
                    <FooterAccessibility darkMode={darkMode} />
                </FooterSupplemental>
                <FooterBottom>
                    <FooterInfo darkMode={darkMode} />
                    {footerLegalComponent ? footerLegalComponent : <FooterLegal darkMode={darkMode} />}
                </FooterBottom>
            </FooterContainer>
        </footer>
    ) : null;
};

export default Footer;

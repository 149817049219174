import { useRouter } from 'next/router';

export const useDomains = () => {
    const { domainLocales, locale, locales } = useRouter();
    if (!domainLocales) {
        return {
            domains: locales.map((locale) => `http://localhost:3069${locale === 'en' ? '' : '/' + locale}`),
            domain: `http://localhost:3069`,
        };
    }
    const domains = domainLocales
        .map((domain) => {
            if (domain.locales) {
                return domain.locales.map((locale) => ({
                    locale: locale,
                    domain: `https://${domain.domain}${locale === domain.defaultLocale ? '' : '/' + locale}`,
                }));
            }
            return {
                locale: domain.defaultLocale,
                domain: `https://${domain.domain}`,
            };
        })
        .flat();
    const domain = domains.find((d) => d.locale === locale).domain;

    return {
        domains,
        domain,
    };
};

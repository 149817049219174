import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { translateUrl } from 'next-translate-routes';

const { publicRuntimeConfig } = getConfig();

const convertToQueryUrl = (obj) => {
    const params = new URLSearchParams(obj);
    for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value)) {
            params.delete(key);
            value.forEach((v) => params.append(key, v));
        }
    }
    return params.toString();
};

export const getNextLink = (href, locale) => {
    const router = useRouter();
    let chosenLocale = locale || router.locale;
    if (!chosenLocale) {
        console.log('No locale selected. Fallback to EN', 'Router:', router);
        chosenLocale = 'en';
    }
    let translatedPath = translateUrl(href.pathname, chosenLocale).replace(/^\//, '').replace(/\/$/, '');
    const possibleRedirect = publicRuntimeConfig.redirects.filter((redirect) => !redirect.has).find((redirect) => redirect.source === `/${translatedPath}`);
    if (possibleRedirect) {
        chosenLocale = 'en';
        if (possibleRedirect.destination.match(/^https?:\/\//i)) {
            translatedPath = new URL(possibleRedirect.destination).pathname.replace(/^\//, '');
        } else {
            translatedPath = possibleRedirect.destination.replace(/^\//, '');
        }
    }
    const domain = publicRuntimeConfig.domains[chosenLocale];

    const translatedPathWithoutPrefix = chosenLocale !== 'en' ? `/${translatedPath.split('/').slice(1).join('/')}` : `/${translatedPath}`;

    const domainWithProtocol = `${domain.protocol}${domain.domain}/`;

    if (translatedPathWithoutPrefix === '/') {
        const finalHomePageHref = `${domainWithProtocol.replace(/\/$/, '')}${publicRuntimeConfig.domains[chosenLocale].prefix === null ? '' : publicRuntimeConfig.domains[chosenLocale].prefix}`;
        return finalHomePageHref.replace(/\/$/, '');
    }
    // return finalHref;
    const finalPageHref = `${domainWithProtocol.replace(/\/$/, '')}${publicRuntimeConfig.domains[chosenLocale].prefix === null ? '' : publicRuntimeConfig.domains[chosenLocale].prefix}${translatedPathWithoutPrefix}${
        href.query ? '?' + convertToQueryUrl(href.query) : ''
    }`;
    return finalPageHref;
};

import getConfig from 'next/config';
import { useRouter } from 'next/router';

import { translateUrl } from 'next-translate-routes';

import { useDomains } from '@hooks/useDomains';

const { publicRuntimeConfig } = getConfig();

export const getTranslatedPathWithoutPrefix = (pathname, locale) => {
    const preparedPathname = pathname.replace(/\[(.*?)\]/, ':$1');
    const translatedPath = translateUrl(preparedPathname, locale).replace(/^\//, '').replace(/\/$/, '');

    const translatedPathWithoutPrefix = locale !== 'en' ? `/${translatedPath.split('/').slice(1).join('/')}` : `/${translatedPath}`;
    if (translatedPathWithoutPrefix === '/' && !publicRuntimeConfig.domains[locale].prefix) {
        return translatedPathWithoutPrefix;
    }
    return translatedPathWithoutPrefix.replace(/\/$/, '').replace(/:([a-z]*)/, '[$1]');
};

export const getCanonical = (path) => {
    const { domain } = useDomains();
    const { locale } = useRouter();
    const translatedPathWithoutPrefix = getTranslatedPathWithoutPrefix(path, locale);

    return `${domain}${translatedPathWithoutPrefix}`;
};

export const getAllCanonicals = (path) => {
    const proto = publicRuntimeConfig.ssl ? 'https://' : 'http://';
    return Object.keys(publicRuntimeConfig.domains).map((locale) => {
        const domain = publicRuntimeConfig.domains[locale].domain;
        const prefix = publicRuntimeConfig.domains[locale].prefix ? publicRuntimeConfig.domains[locale].prefix : '';
        const translatedPathWithoutPrefix = getTranslatedPathWithoutPrefix(path, locale);
        const finalHref = `${proto}${domain.domain}${prefix}${translatedPathWithoutPrefix}`;
        return {
            locale,
            url: finalHref,
        };
    });
};

export const alternatePaths = (path) => {
    const localizedPaths = {};
    publicRuntimeConfig.locales.forEach((queryLocale) => {
        localizedPaths[queryLocale] = path;
    });

    let locales = publicRuntimeConfig.locales;

    const localeAlternatePaths = locales.map((locale) => {
        const localizePathname = getTranslatedPathWithoutPrefix(localizedPaths[locale], locale);
        return { locale, pathname: localizePathname !== '' ? localizePathname : '/' };
    });
    return localeAlternatePaths;
};

export const alternatePath = (path, locale, alternateQuery) => {
    const allAlternatePaths = alternatePaths(path, alternateQuery);
    return allAlternatePaths.find((alternate) => alternate.locale === locale)?.pathname ?? null;
};

export const alternates = (path, alternateQuery) => {
    const proto = publicRuntimeConfig.ssl ? 'https://' : 'http://';
    const localizedPaths = {};
    if (alternateQuery) {
        Object.keys(alternateQuery).forEach((queryLocale) => {
            localizedPaths[queryLocale] = path.replace('[slug]', alternateQuery[queryLocale].slug);
        });
    } else {
        publicRuntimeConfig.locales.forEach((queryLocale) => {
            localizedPaths[queryLocale] = path;
        });
    }

    let locales = publicRuntimeConfig.locales;
    if (alternateQuery) {
        locales = Object.keys(alternateQuery);
    }

    const localeAlternates = locales.map((locale) => ({
        hrefLang: locale.replace('vn', 'vi'),
        locale,
        href:
            proto +
            publicRuntimeConfig.domains[locale].domain +
            (publicRuntimeConfig.domains[locale].prefix ? publicRuntimeConfig.domains[locale].prefix : '') +
            (localizedPaths[locale] ? getTranslatedPathWithoutPrefix(localizedPaths[locale], locale) : ''),
    }));

    const alternates = [
        {
            hrefLang: 'x-default',
            href:
                proto +
                publicRuntimeConfig.domains['en'].domain +
                (publicRuntimeConfig.domains['en'].prefix ? publicRuntimeConfig.domains['en'].prefix : '') +
                (localizedPaths['en'] ? getTranslatedPathWithoutPrefix(localizedPaths['en'], 'en') : ''),
        },
        ...localeAlternates
            .map((alternate) => {
                return {
                    hrefLang: alternate.hrefLang,
                    href:
                        proto +
                        publicRuntimeConfig.domains[alternate.locale].domain +
                        (publicRuntimeConfig.domains[alternate.locale].prefix ? publicRuntimeConfig.domains[alternate.locale].prefix : '') +
                        getTranslatedPathWithoutPrefix(localizedPaths[alternate.locale], alternate.locale),
                };
            })
            .filter((alternate) => {
                if (alternateQuery) {
                    return true;
                }
                const translatedUrl = translateUrl(path, alternate.hrefLang);
                const isNotRedirected = typeof publicRuntimeConfig.redirects.find((redirect) => redirect.source === translatedUrl && !redirect.has) === 'undefined';
                const isNotDisabled =
                    typeof publicRuntimeConfig.disabledRoutes.find((disabledRoute) => disabledRoute.locale.replace('vn', 'vi') === alternate.hrefLang && disabledRoute.route === getTranslatedPathWithoutPrefix(path, disabledRoute.locale)) ===
                    'undefined';
                return isNotRedirected && isNotDisabled;
            }),
    ];
    return alternates;
};

export const canonical = (path, locale) => {
    const proto = publicRuntimeConfig.ssl ? 'https://' : 'http://';
    return proto + publicRuntimeConfig.domains[locale].domain + (publicRuntimeConfig.domains[locale].prefix ? publicRuntimeConfig.domains[locale].prefix : '') + getTranslatedPathWithoutPrefix(path, locale);
};

import { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import useTranslation from 'next-translate/useTranslation';

import withI18nData from '@helpers/i18n';

const I18nContext = createContext();

export function I18nWrapper({ children }) {
    const { locale } = useRouter();
    let { t, lang } = useTranslation();
    const [trans, setTrans] = useState(() => t);

    useEffect(() => {
        // Promise.all([import('../locales/en/pages.json'), import('../locales/en/base.json')]).then(
        //   (values) => {
        //     const namespaces = values.map(({ default: namespace }) => namespace);
        //     setTrans(() =>
        //       withI18nData(t, {
        //         pages: namespaces[0],
        //         base: namespaces[1],
        //       }),
        //     );
        //   },
        // );
    }, []);

    return <I18nContext.Provider value={{ t: trans }}>{children}</I18nContext.Provider>;
}

export function useI18nContext() {
    return useContext(I18nContext);
}
